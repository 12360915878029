.banner {
  box-sizing: border-box;
  position:fixed;
  top:0;
  height:50px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: baseline;
  padding: 1em;
  margin-bottom: 1em;
  background-color: rgb(192, 45, 26);
  color: #fff;
  z-index:1000;
}