.timer-body {
}

.timer {
    max-height: 70vh;
    height: 70vh;
}

.half {
    max-width:70vh;
    max-height: 70vh;
    height: 100%;
    margin: 0 auto;
}

.timer-text {
    font-size: 10vmin;
    color: #1a2b4d;
    text-align: center;
    display: flex;
    max-height: 15vh;
    height: 15vh;
}

.internal-timer-text {
    font-size: 12vmin;
    color: #1a2b4d;
}

.timer-name {
    font-size: 10vmin;
    color: #1a2b4d;
    text-align: center;
    max-height: 15vh;
    height: 15vh;
    bottom: auto;
}