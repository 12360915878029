.App {
  text-align: center;
  overflow: hidden;
}

.first {
  background: #1a2b4d;
  min-height: 100vh;
  max-height: 100vh;
}

.second {
  min-height: 100vh;
  max-height: 100vh;
}

.timer {
}

.map {
  background: #1a2b4d;
  padding: 18px;
  height: 45vh;
  max-height: 45vh;
}

.break {
  -moz-border-bottom-colors: none;
  -moz-border-image: none;
  -moz-border-left-colors: none;
  -moz-border-right-colors: none;
  -moz-border-top-colors: none;
  border-color: #EEEEEE -moz-use-text-color #FFFFFF;
  border-style: solid none;
  border-width: 1px 0;
  margin: 10px;
  margin-bottom: 5px;
  background: #CAA042;
}

.conditions {
  max-height: 50vh;
  height: 50vh;
} 