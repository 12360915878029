.main {
    color: #FFFFFF;
}

.weather {
    max-height: 13vh;
    line-height: 13vh;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.icon-wrapper {
    padding-right: 10px !important;
}

.icon {
    float: right;
    max-height: 13vmin;
    margin: 0;
}

.temperature {
    height: 13vh;
    padding-left: 0 !important;
}

.label-text {
    float: center;
    display: float;
    font-size: 2vmin;
    max-width: 100%;
    margin: 0;
    padding: 0;
}
.temp-text {
    float: left;
    font-size: 9vmin;
    max-width: 100%;
    margin: 0;
}

.info-text {
    font-size: 4vmin;
}

.last-updated {
    font-size: 1.5vmin;
    margin: 8px;
    height: 4vh;
    max-height: 5vh;
    line-height: 2vh;
    margin: 0 !important;
}


.logo {
    height: auto;
    width: 200px;
    max-height: 6vh;
    margin-top: 1em;
}
